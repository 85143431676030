import React, { useRef, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "../styles/360-catalog.scss";
import "../styles/auto.scss";
import "../styles/furniture.scss";
import CommonSection from "../components/CommonSection";
import CaseStudy from "../sections/homepage/CaseStudy";
import "bootstrap/dist/css/bootstrap.min.css";
import ClientLogoBanner from "../components/ClientLogoBanner";
import AccordionSection from "../components/AccordionSection";
import StatColumn from "../components/StatColumn";
import ButtonBanner from "../components/ButtonBanner";
import Testimonials from "../sections/homepage/Testimonials";
import Blogs from "../sections/homepage/Blogs/Blogs";
import { Container } from "react-bootstrap";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";

const Sanitaryware = () => {
  const caseStudies = [
    {
      image: "../../images/casestudy/ashirvad.webp",
      title: "Empower your sanitaryware sales",
      footer: "",
      link: "/ashirvad",
    },
    {
      image: "../../images/casestudy/codesustain.webp",
      title: "360 experience for ecommerce platform",
      footer: "",
      link: "/codesustain",
    },
    {
      image: "../../images/casestudy/craftmaestro.webp",
      title: "Experiential buying for handicrafts",
      footer: "",
      link: "/craft-maestro",
    },
  ];
  let logoList = [
    "../../images/trustedclients/ashirvad1.svg",
    "../../images/trustedclients/wakefit1.svg",
    "../../images/trustedclients/featherlite1.svg",
    "../../images/trustedclients/onlymat.svg",
    "../../images/trustedclients/artevenue.svg",
    "../../images/trustedclients/spinny1.svg",
  ];
  const advantages = [
    {
      image: "../../images/sanitaryware/augmented-reality-in-retail.png",
      imageWebp: "../../images/sanitaryware/augmented-reality-in-retail.webp",
      title: "One-stop solution",
      sub: "Create 3D renders of all the products, manage them online, and upload them on your site.",
    },
    {
      image: "../../images/sanitaryware/augmented-reality-in-retail.png",
      imageWebp: "../../images/sanitaryware/augmented-reality-in-retail.webp",
      title: "Flexible customization to customers",
      sub: "Showcase all product customizations and product demos virtually with a single click.",
    },
    {
      image: "../../images/sanitaryware/augmented-reality-in-retail.png",
      imageWebp: "../../images/sanitaryware/augmented-reality-in-retail.webp",
      title: "Unlimited templates",
      sub: "We can create as many virtual templates as you need. This will help users visualize the fittings in the life-like space for easy decision-making.",
    },
    {
      image: "../../images/sanitaryware/augmented-reality-in-retail.png",
      imageWebp: "../../images/sanitaryware/augmented-reality-in-retail.webp",
      title: "Easy integration",
      sub: "Our software can be installed seamlessly on your website in a few minutes.",
    },
    {
      image: "../../images/sanitaryware/augmented-reality-in-retail.png",
      imageWebp: "../../images/sanitaryware/augmented-reality-in-retail.webp",
      title: "Hassle-free distribution",
      sub: "Single click download of the 3D renders for multi platform distribution.",
    },
  ];

  return (
    <Layout>
      <Helmet>
        <title>HelloAR: Sanitaryware Brands| AR VR Company</title>
        <meta
          name="description"
          content="Double your revenues by embedding AR VR software in your online sanitaryware brand portfolio. We're the known among the best AR VR companies for sanitaryware."
        />
        <meta name="keywords" content="sanitaryware brands, ar vr companies" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../images/auto/car_360.jpg" />
      </Helmet>
      <Container fluid className="furniture">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <div className="section-1">
          <CommonSection
            title="Empower your sales representatives with truly immersive digital catalogs"
            sub="Empower users to view all your products & product customizations in a virtual space to understand how they look and feel."
            buttonText="REQUEST A DEMO"
          >
            <VideoPlayer
              videoThumbnail="../../images/thumbnail/sanitaryware.webp"
              videoUrl="../../../videos/ar-vr-mr.mp4"
              square
            />
          </CommonSection>
        </div>
        <div className="spacer-sm"></div>
        <div className="section-2">
          {/* <ClientLogoBanner logoList={logoList} /> */}
          {/* <CaseStudy caseStudies={caseStudies} /> */}
        </div>
        <div className="spacer" />
        <AccordionSection
          title="HelloAR platform empowers you to"
          items={[
            {
              title: "Create 3D product renders",
              sub: "Create 3D renders of all your products within a few hours and manage all the needs for the visual catalog.",
              image: "../../images/sanitaryware/ar-shopping.png",
              imageWebp: "../../images/sanitaryware/ar-shopping.webp",
            },
            {
              title: "Enable unlimited product configuration",
              sub: "Display all the colors, sizes, shapes, and materials of each product in a single click.",
              image: "../../images/sanitaryware/ar-shopping.png",
              imageWebp: "../../images/sanitaryware/ar-shopping.webp",
            },
            {
              title: "Enable virtual reality",
              sub: "We allow users to see products in the virtual setting to understand the product better.",
              image: "../../images/sanitaryware/ar-shopping.png",
              imageWebp: "../../images/sanitaryware/ar-shopping.webp",
            },
            {
              title: "Instant invoice/quote generation",
              sub: "Generate invoices and quotes and share with multiple users on the go.",
              image: "../../images/sanitaryware/ar-shopping.png",
              imageWebp: "../../images/sanitaryware/ar-shopping.webp",
            },
            {
              title: "Generate bill of materials instantly",
              sub: "Our software integrates with your CRM or ERP and creates bill of materials in realtime.",
              image: "../../images/sanitaryware/ar-shopping.png",
              imageWebp: "../../images/sanitaryware/ar-shopping.webp",
            },
          ]}
          mainIndex={3}
        />

        <div className="spacer" />
        <StatColumn
          title="How sanitaryware is changing with VR"
          items={[
            {
              stat: "94%",
              info: "Products with VR content see 94% higher conversion rates​",
            },
            {
              stat: "70%",
              info: "Suppliers with AR sales presentations are about 70% more likely to seal the deal",
            },
            {
              stat: "90%",
              info: "Around 90% of B2B sellers are now working via video conferencing",
            },
          ]}
        />
        <div className="spacer" />
        <ClientLogoBanner logoList={logoList} />

        {/* <CaseStudy /> */}
        <div className="spacer" />
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light px-3">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
        <div className="spacer"></div>
        <AccordionSection
          title="HelloAR advantages"
          items={advantages}
          mainIndex={3}
        />
        <div className="spacer" />

        <Testimonials />
        <div className="spacer"></div>
        {/* <div className="spacer-sm"></div> */}
        {/* <Blogs /> */}
        {/* <div className="spacer"></div> */}
      </Container>
    </Layout>
  );
};

export default Sanitaryware;
